import React from "react";
import '../About.css';


const ContactMe = () => {
  return (
      <div id="contact">
          <div className="contact-container">
              <h1 className="contact-title">Let's ...🎸 together! </h1>
              <form className="contact-form">
                  <input type="text" id="name" className="contact-input" placeholder="Your Name"/>
                  <input type="email" id="email" className="contact-input" placeholder="Your Email"/>
                  <textarea id="message" className="contact-input contact-textarea"
                            placeholder="Your Message"></textarea>
                  <button type="submit" className="contact-button">Send Message</button>
              </form>
          </div>

      </div>
  );
};


export default ContactMe;