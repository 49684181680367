import React from "react";
import '../library.css';
import SearchComponents from './SearchComponents';
import TopBooks from './TopBooks';
import { useNavigate } from 'react-router-dom';


const Projects = () => {
  const navigate = useNavigate(); // For navigating programmatically

  return (
      <div id="projects">
          <div className="button-container">
              <button onClick={() => navigate(-1)} className="text-button">Home</button>
              <a href="https://github.com/Anya-Yelis/Library-Flask/tree/main" target="_blank" rel="noopener noreferrer"
                 class="text-button">More on Github</a>
          </div>

          <h1> 📚 Welcome to the Library! </h1>
          {/*<h2> Search anything</h2>*/}
          <SearchComponents/>
          <TopBooks/>
      </div>
  );
};


export default Projects;
