import React from "react";
import '../About.css';
import { Link } from 'react-router-dom';

const About = () => {
  return (
      <div id="about">

        <div class="container">
          <div className="current-section">

              <h2> <img src="/guitar.png"/>What I'm currently doing:</h2>

              <h3>I am finishing up my <strong>Bachelor's in Data Science</strong> at the University of
                  Illinois at Chicago. </h3>
              <h3>
                  Working as a <strong>Data Analyist Co-Op</strong> at a global healthcare company, Fresenius
                  Kabi.</h3>


          </div>
            <div className="current-section-right">
                <h2>
                    <Link to="/projects">Check out my Library Management System</Link>
                </h2>
                <h2>
                    More stuff to be added 🛠️</h2>
            </div>

        </div>
      </div>
  )
      ;
};

export default About;