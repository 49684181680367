import React, { useState } from 'react';
import '../SearchResults.css';


function SearchComponents() {
  const [keywords, setKeywords] = useState('');
  const [results, setResults] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index); // Toggle expanded section
  };

  const liveSearch = async (e) => {
    const query = e.target.value;
    setKeywords(query);

    if (query.length > 0) {
      try {
        const response = await fetch('http://localhost:5001/full_text_search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest', // Important header to identify AJAX request
          },
          body: new URLSearchParams({ keywords: query }),
        });

        const data = await response.json();
        setResults(data.results);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      setResults([]); // Clear results if input is empty
    }
  };

  return (
      <div>
        <div className="center-container">
          {/*<label htmlFor="keywords">Enter Keywords:</label>*/}
          <input
              type="text"
              id="keywords"
              name="keywords"
              value={keywords}
              onChange={liveSearch}
              autoComplete="off"
              className="input"
          />
        </div>



        {/* Div to display dynamic search results */}
        <div id="search-results" className="results-grid">
            {results.length > 0 ? (
        results.map((result, index) => (
          <div
            className="result-tile"
            key={index}
            onClick={() => handleToggle(index)} // Toggle book details
          >
            <div className="result-content">
              <img
                src={result[14]} // Assuming cover image at index 14
                alt="Cover"
                className="result-cover"
              />
              <div className="result-info">
                <h2>{result[6] || result[10]}</h2>
              </div>

              {/* Conditionally render the expanded book details */}
              {expandedIndex === index && (
                  <div className="book-details">
                    <p>
                      {result[11]}
                    </p>
                    <p>
                      <strong>Publisher:</strong> {result[2]}
                    </p>
                    <p>
                      <strong>Date:</strong> {result[3]}
                    </p>
                    <p>
                      <strong>Available Copies:</strong>{' '}
                      {result[result.length - 2] === 'Unlimited'
                          ? 'Unlimited'
                          : result[result.length - 2]}
                    </p>
                    {result[result.length - 2] !== 'Unlimited' &&
                    parseInt(result[result.length - 2]) === 0 ? (
                        <p>
                          <strong>Next Available Date:</strong>{' '}
                          {result[result.length - 1]}
                        </p>
                    ) : null}
                  </div>
              )}
            </div>
          </div>
        ))
            ) : (
              <p>Search Anything.</p>
          )}
        </div>
      </div>
  );
}

export default SearchComponents;
