

import React from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import ContactMe from "./pages/ContactMe";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';


const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* Main view with Home, About, and ContactMe */}
          <Route path="/" element={<MainView />} />

          {/* Projects view */}
          <Route path="/projects" element={<Projects />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;


const MainView = () => {
  const navigate = useNavigate(); // Hook for navigation

  return (
    <div>
      <Home />
      <About />
      <ContactMe />
      {/*/!* Button to navigate to the Projects page *!/*/}
      {/*<button onClick={() => navigate('/projects')}>*/}
      {/*  Check out my Library Management System*/}
      {/*</button>*/}
    </div>
  );
};