import React, { useState } from 'react';
import '../App.css';

const Home = () => {
  const [image, setImage] = useState('/6empty_scaled.png');
  const [containerColor, setContainerColor] = useState('#0c142d'); // Initial container color
  const [showNavBar, setShowNavBar] = useState(false);

  const changeImage = () => {
        const newImage = image === '/6empty_scaled.png' ? '/6lights_scaled.png' : '/6empty_scaled.png';
        setImage(newImage);
        // Change background color based on the image
        setContainerColor(newImage === '/6lights_scaled.png' ? '#162254' : '#0c142d');
    };

  const onAnimationEnd = () => {
     setTimeout(() => {
      changeImage();
      setShowNavBar(true);
    }, 1000); // 2000ms = 2 seconds delay
  };

  return (
      <div className="App">
          <div
              className="image-container"
              style={{backgroundColor: containerColor}}
              onAnimationEnd={onAnimationEnd}
          >
              <img src={image} alt="toggle-img" className="base-image"/>
              <img src="/heart.png" alt="bouncing-img" className="bouncing-image"/>
              <button onClick={changeImage} className="change-btn">Change Image</button>
              <div className={"over-img"}>
                  <h1 className="name">Anna Yelisseyeva</h1>
                  <h2 className="title">Data Science Student</h2>

              </div>

          </div>

          <div className="bottom-container">
              <nav className="navbar">
                  <ul>
                      <li>
                          <a href="https://www.linkedin.com/in/annayelis/" target="_blank" rel="noopener noreferrer">
                              <img src={`${process.env.PUBLIC_URL}/linked_pic.png`} alt="LinkedIn" className="nav-icon"/>
                          </a>
                      </li>
                      <li>
                          <a href="https://github.com/Anya-Yelis" target="_blank" rel="noopener noreferrer">
                              <img src={`${process.env.PUBLIC_URL}/git_pic.png`} alt="GitHub" className="nav-icon"/>
                          </a>
                      </li>
                      <li><a href="#about">About</a></li>
                      {/*<li><a href="#projects">Projects</a></li>*/}
                      <li><a href="#contact">Contact me</a></li>
                  </ul>
              </nav>
          </div>


      </div>
  );
};


export default Home;
